<template>
    <div>
        <SubHeader :title="'Transaction History'" :link="'/mypage'" right_home_btn />
        <div class="content_wrap">
            <ul class="d-flex gap-2 text-center fs-px-12 overflow-auto">
                <li v-for="(item,index) in coin_list" :key="index"  class="fw-bold rounded-3 py-3 flex-center-center cursor-pointer" :class="{'bg-main': coin == item, 'bg-yellow-4': coin != item, 'flex-shrink-0 w-10' : coin_list.length > 6, 'w-100' : coin_list.length <= 6}" @click="ChangeCoin(item)">
                    <span v-if="item == ''">
                        ALL
                    </span>
                    <span v-else>
                        {{item}}
                    </span>
                </li>
                <!-- <li class="bg-main fw-bold w-20 rounded-3 py-3 flex-center-center cursor-pointer" @click="ChangeCoin('')">
                    ALL
                </li>
                <li class="bg-main fw-bold w-20 rounded-3 py-3 flex-center-center cursor-pointer" @click="ChangeCoin('TRX')">
                    TRX
                </li>
                <li class="bg-main fw-bold w-20 rounded-3 py-3 flex-center-center cursor-pointer" @click="ChangeCoin('USDT')">
                    USDT
                </li>
                <li class="bg-main fw-bold w-20 rounded-3 py-3 flex-center-center cursor-pointer" @click="ChangeCoin('ETH')">
                    ETH
                </li>
                <li class="bg-main fw-bold w-20 rounded-3 py-3 flex-center-center cursor-pointer" @click="ChangeCoin('BTC')">
                    BTC
                </li>
                <li class="bg-main fw-bold w-20 rounded-3 py-3 flex-center-center cursor-pointer" @click="ChangeCoin('BNB')">
                    BNB
                </li> -->
            </ul>

            <div class="my-3">
                <ul class="last-border-0 text-white">
                    <li class="border-bottom border-yellow-1 mb-3 pb-3" v-for="(item,index) in list" :key="index" @click="GotoMove(item.target)">
                        <div class="flex-between-center mb-1">
                            <span>{{item.memo}}</span>
                            <span v-if="item.status =='H'">( {{CheckSign(item.type)}} {{CheckBalance(item.coin,item.balance)}} {{item.coin}} )</span>
                            <span v-if="item.status !='H'"> {{CheckSign(item.type)}} {{CheckBalance(item.coin,item.balance)}} {{item.coin}} </span>
                            
                        </div>
                        <div class="flex-between-center fs-px-12">
                            <span>{{item.create_time}}</span>
                            <span>{{CheckStatus(item.type,item.status)}}</span>
                            
                        </div>
                        <div class="flex-between-center fs-px-12">
                            <span v-if="item.target!='' && item.target!=null">Deal Number : {{item.target}}</span>
                            
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import Footer from '@/components/common/Footer.vue'
const CryptoJS = require("crypto-js");

export default {
    components:{
        SubHeader, Footer
    },
    data(){
        return{
            coin_list: ['','OCB','TRX','USDT','ETH','BTC','BNB'],
            common_coin : [],
            // coin_list: ['','BTC','ETH'],
            coin : '',
            list: [],
            
            // front ui
        }
    },
    mounted(){
        this.GetCoinInfo();
        
    },
    methods:{
        CheckBalance(coin,balance){
            let w_up = '';
            for (const el of this.common_coin) {
                if(coin ==el.symbol){
                    w_up = el.w_up;
                }
            }
            const balance_t = balance+'';
            if(balance_t > 0 ){
                const float_arr = balance_t.split('.');
                let price = '';
                if(float_arr.length == 2 ){
                    let f_string = float_arr[1]+ '';
                    price = float_arr[0] +'.'+f_string.substring(0, w_up);
                }else{
                    price = balance;
                }
                return price;
            }else{
                return balance_t;
            }
        },
        
        GotoMove(room_code){
            if(room_code !=''){
                this.$router.push({path:`/deal/detail/${room_code}`})
            }
            
        },
        ChangeCoin(coin){
            this.coin = coin;
            this.GetHistory();
        },
        CheckType(type){
            if(type =='W'){
                return 'Withdrawal'
            }else if(type =='D'){
                return 'Deposit'
            }
        },
        CheckSign(type){
            if(type =='W'){
                return '-'
            }else if(type =='D'){
                return '+'
            }
        },
        CheckStatus(type,status){
            if(type =='W'){
                if(status =='H'){
                    return 'Ongoing'    
                }else if(status =='Y'){
                    return 'Completed'    
                }else if(status =='C'){
                    return 'Withdrawal failure'    
                }
            }else if(type =='D'){
                if(status =='Y'){
                    return '';
                }

            }
            
        },
        GetCoinInfo(){
            this.$http.post('/member/transaction/GetCoinInfo',{}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            this.common_coin = body.list;
                            this.GetHistory()
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },
        GetHistory(){
            const coin = this.coin;
            const body = {coin};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.pp = false;
            this.loading = true;
            this.$http.post('/member/transaction/GetHistory',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            this.list = body.list;
                            
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        }
    }

}
</script>

<style lang="scss" scoped>
</style>